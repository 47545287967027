import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "./../../../assets/img/world_flip.gif";
import { routeNames } from "routes";

import { Container,Button,Image } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const Navbar = () => {
  const { loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  return (
    <>
    {isMobile ? (<BsNavbar className="sticky-top top-0 z-30 shadow-sm backdrop-filter backdrop-blur-lg bg-opacity-30 py-3" style={{zIndex:10000}}>
      <Container fluid className="mx-3">
      <Link to={routeNames.map} className="text-3xl text-dark font-bold no-underline">
        <BsNavbar.Brand className="d-flex align-items-center">
          <Image src={logo} className="elrond-logo" />
        </BsNavbar.Brand>
			</Link>
      <BsNavbar.Toggle aria-controls="responsive-navbar-nav" />
      <BsNavbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
        {loggedIn && (
          <NavItem>
          <Link
            to={routeNames.map}
            className="btn shadow bg-dark text-light font-weight-bold mx-3"
            data-testid="loginBtn"
          >
            Home
          </Link>
            <Button variant="shadow bg-light text-dark font-weight-bold" onClick={logOut}>
              Logout
            </Button>

        </NavItem>
        )}
        {!loggedIn && (
        <NavItem>
          <Link
            to={routeNames.map}
            className="btn shadow bg-dark text-light font-weight-bold mx-3"
            data-testid="loginBtn"
          >
            Home
          </Link>

          <Link
            to={routeNames.unlock}
            className="btn shadow bg-light text-dark font-weight-bold "
            data-testid="loginBtn"
          >
            Login
          </Link>

        </NavItem>

        )}
      </Nav>

      </BsNavbar.Collapse>
    </Container>
  </BsNavbar>):(<BsNavbar className="sticky-top top-0 z-30 shadow-sm backdrop-filter backdrop-blur-lg bg-opacity-30 py-3" style={{zIndex:10000}}>
    <Container fluid className="mx-3">
    <Link to={routeNames.map} className="text-3xl text-dark font-bold no-underline">
      <BsNavbar.Brand className="d-flex align-items-center">
        <Image src={logo} className="w-14" />
      </BsNavbar.Brand>
    </Link>
    <BsNavbar.Toggle aria-controls="responsive-navbar-nav" />
    <BsNavbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto">
      {loggedIn && (
        <NavItem>
        <Link
          to={routeNames.map}
          className="shadow bg-dark text-light btn font-weight-bold btn-lg outline-light mx-3"
          data-testid="loginBtn"
        >
          Home
        </Link>
          <Button variant="shadow bg-light text-dark font-weight-bold" size="lg" onClick={logOut}>
            Logout
          </Button>

      </NavItem>
      )}
      {!loggedIn && (
      <NavItem>
      <Link
        to={routeNames.map}
        className="shadow bg-dark text-light btn font-weight-bold btn-lg outline-light mx-3"
        data-testid="loginBtn"
      >
        Home
      </Link>
        <Link
          to={routeNames.unlock}
          className="shadow bg-light text-dark btn font-weight-bold btn-lg outline-light"
          data-testid="loginBtn"
        >
          Login
        </Link>

      </NavItem>

      )}
    </Nav>

    </BsNavbar.Collapse>
  </Container>
</BsNavbar>)}
</>
  );
};

export default Navbar;
