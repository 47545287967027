import axios from "axios";
import L from 'leaflet';
import { collectionTicker } from "config";


export var highlightFeature = function(e:any) {
  var layer = e.target;

  layer.setStyle({
      weight: 5,
      color: '#999',
      dashArray: '',
      fillOpacity: 0.2
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
  }
}

export var resetHighlightFeature = function(e:any) {
  var layer = e.target;
  layer.setStyle({
      weight: 1,
      opacity: 1,
      color: '#bfbfbf',
      dashArray: '3',
      fillOpacity: 0.2
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
  }
}




export async function getProfileNFTs(address: any,apiAddress: any): Promise<any> {
    try {
        const { data } = await axios.get(`${apiAddress}/accounts/${address}/nfts?&size=4444&type=SemiFungibleESDT,NonFungibleESDT`);
    return {
      data: data,
      success: data !== undefined,
    };
    } catch (err) {
      return {
        success: false,
      };
    }
};



export async function getCollections(apiAddress: string): Promise<any> {
  try {
      const { data } = await axios.get(`${apiAddress}/nfts?collection=${collectionTicker}&size=4444`);
  return {
    data: data,
    success: data !== undefined,
  };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const decodeBase64 = function (base64: string){
  let buff = Buffer.from(base64, 'base64');
  return buff.toString("utf-8");
}
