import React, {} from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Route, Switch ,Link} from "react-router-dom";
import Layout from "./components/Layout";
import PageNotFoud from "./components/PageNotFoud";
import * as config from "./config";
import { ContextProvider } from "./context";
import routes, { routeNames } from "./routes";
import { Button, Modal} from 'react-bootstrap';

const App = () => {
  return (
    <Dapp.Context config={config} >
      <ContextProvider >
        <Layout>

          <Switch>
            <Route
              path={routeNames.unlock}
              component={() => (
                <Modal
                  show={true}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  size="lg"
                  className=" fade my-modal bg-dark"
                >
                <Dapp.Pages.Unlock
                  callbackRoute={routeNames.map}
                  title={config.dAppName}
                  lead="Please select your login method:"
                  ledgerRoute={routeNames.ledger}
                  walletConnectRoute={routeNames.walletconnect}
                />
                <Link className="text-center" to={routeNames.map}>
                  <Button className="shadow bg-light text-dark font-weight-bold " style={{borderColor:"transparent"}} size="lg" >
                    Back
                  </Button>
                </Link>
                </Modal>
              )}
              exact={true}
            />

            <Route
              path={routeNames.ledger}
              component={() => (
                <Modal
                  show={true}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  size="lg"
                  className="fade my-modal bg-dark"
                >
                <Dapp.Pages.Ledger callbackRoute={routeNames.map} />
                <Link className="text-center" to={routeNames.unlock}>
                  <Button className="shadow bg-light text-dark font-weight-bold " style={{borderColor:"transparent"}} size="lg" >
                    Back
                  </Button>
                </Link>
                </Modal>
              )}
              exact={true}
            />
            <Route
              path={routeNames.walletconnect}
              component={() => (
                <Modal
                  show={true}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  size="lg"
                  className="fade my-modal bg-dark"
                >
                <Dapp.Pages.WalletConnect
                  callbackRoute={routeNames.map}
                  logoutRoute={routeNames.map}
                  title="Maiar Login"
                  lead="Scan the QR code using Maiar"
                />
                <Link className="text-center" to={routeNames.unlock}>
                  <Button className="shadow bg-light text-dark font-weight-bold " style={{borderColor:"transparent"}} size="lg" >
                    Back
                  </Button>
                </Link>
                </Modal>
              )}
              exact={true}
            />

            {routes.map((route, i) => (
              <Route
                path={route.path}
                key={route.path + i}
                component={route.component}
                exact={true}
              />
            ))}
            <Route component={PageNotFoud} />
          </Switch>

        </Layout>
      </ContextProvider>
    </Dapp.Context>
  );
}

export default App;
