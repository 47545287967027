import * as Dapp from "@elrondnetwork/dapp";

export const dAppName = "Collection eWRLD - NFTs";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

//export const walletConnectBridge = "https://walletconnect-bridge.maiar.com";
export const walletConnectBridge = "https://bridge0.aerovek.org";
export const walletConnectDeepLink = "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const BSKdecimals = 16;
export const toastDuration = 10000;

// export const requestLabelSell = "eMoonNFTSell";
// export const requestLabelBuy = "eMoonNFTBuy";
// export const requestLabelRemove = "eMoonNFTRemove";
// export const requestSep = "!";
//
// export const sellFee = "0.01";
// export const regexTokenIdentifier = /([A-Z]*-[a-zA-Z0-9]*)(-[0-9a-zA-Z]*)?/g;

//MAIN NET



export const network: Dapp.NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};

export const collectionTicker = "EWRLD-d13264";
export const contractAddress = "erd1qqqqqqqqqqqqqpgq309ekprt0awp5zuz0cddgzralxtu5vcq393qe65u7a";

// export const BSKidentifier = "BSK-baa025";
export const serverAddress = "https://ewrld.xyz/";
// export const depositWalletAdresse = "erd1atmdg9pytmr56a7sy2xe29sjx4z2n459g8j5jlmppf86lgla393qdjn92m";
//

//TEST NET
/*
export const network: Dapp.NetworkType = {
  id: "testnet",
  name: "Testnet",
  egldLabel: "xEGLD",
  walletAddress: "https://testnet-wallet.elrond.com",
  apiAddress: "https://testnet-api.elrond.com",
  gatewayAddress: "https://testnet-gateway.elrond.com",
  explorerAddress: "http://testnet-explorer.elrond.com/",
};
*/
// export const collectionTicker = "EWRLD-e35c26";
// export const contractAddress = "erd1qqqqqqqqqqqqqpgq90nj749xemy3np3545gd2xpjpc72jktu2ghspq0rge";

// MainNet


// export const contractAddress = "erd1qqqqqqqqqqqqqpgqa4n54978m9jdtasj740c7zslrwdyuxlz2ghsr5q9xn";
//DEV NET

// export const network: Dapp.NetworkType = {
//   id: "devnet",
//   name: "Devnet",
//   egldLabel: "xEGLD",
//   walletAddress: "https://devnet-wallet.elrond.com",
//   apiAddress: "https://devnet-api.elrond.com",
//   gatewayAddress: "https://devnet-gateway.elrond.com",
//   explorerAddress: "http://devnet-explorer.elrond.com/",
// };
/*
export const BSKidentifier = "BSK-0cb1be"; //Dev net
export const serverAddress = "https://api.emoon.space/";
export const depositWalletAdresse = "erd1ghq0xx6cqmre3203sgpl8aqsnryh2r97d2zv5yzmrnjup3t33wnqyy3duk";
*/
